

export function MaisResults({ title, index, updateData, data, changeResultado }) {
    const image1 = require(`../assets/images/mais1.png`)
    const image2 = require(`../assets/images/mais2.png`)
    const image3 = require(`../assets/images/mais3.png`)

    return (
        <div style={{ height: '100%', padding: 5, width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', position: 'relative', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <h2 style={{ color: '#fff', fontWeight: 'bold', fontFamily: 'Amazing Slab Trial', fontSize: 35, lineHeight: 1 }}>Aprenda mais sobre!</h2>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    {respostas.map((e) => (
                        <button className="buttonPergunta" style={buttonStyle} onClick={() => changeResultado(e.resposta)}>{e.title}</button>
                    ))}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <img className="imageMais" src={image1} style={{ width: '50%', maxHeight: 180, objectFit: 'contain', position: 'relative', left: '8%' }} />
                <img className="imageMais" src={image2} style={{ width: '50%', maxHeight: 180, objectFit: 'contain' }} />
                <img className="imageMais" src={image3} style={{ width: '50%', maxHeight: 180, objectFit: 'contain', position: 'relative', right: '8%' }} />
            </div>
        </div>
    )
}

const respostas = [
    {
        title: 'Ansiedade Normal',
        resposta: 0
    },
    {
        title: 'Ansiedade Leve',
        resposta: 1

    },
    {
        title: 'Ansiedade Moderada',
        resposta: 2

    },
    {
        title: 'Ansiedade Grave',
        resposta: 3
    },
]

const buttonStyle = {
    backgroundColor: '#fff',
    marginTop: 20,
    width: '100%',
    color: '#FF6A00',
    border: 'none',
    padding: '15px 30px',
    borderRadius: '15px',
    fontSize: '25px',
    fontWeight: 'bold',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease',
    fontFamily: 'Sensa Wild Fill'
};

buttonStyle[':hover'] = {
    backgroundColor: '#FF6A00',
    color: '#fff',
};