import AnsiedadeInit from '../assets/images/ansiedade_inicio.png'


export function Inicio({ nextSlide }) {

    return (
        <div style={{ padding: 30, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', position: 'relative', height: 'auto', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', zIndex: 2 }}>
                    <h2 style={{ color: '#fff', fontWeight: 'bold', fontFamily: 'Amazing Slab Trial' }}>Conheça um dos instrumentos psicológicos que avalia a ansiedade!</h2>
                    <h1 style={{ color: '#fff', fontWeight: 'regular' }}>ESCALA DE ANSIEDADE DE HAMILTON</h1>
                </div>
                <div style={{ width: '70%', height: '100%', position: 'relative', zIndex: 1 }}>
                    <img src={AnsiedadeInit} style={{ position: 'absolute', right: -20, bottom: 0, width: 220, objectFit: 'contain' }} />
                </div>
            </div>
            <button style={buttonStyle} onClick={nextSlide}>Responder Agora</button>
            <div style={{ position: 'absolute', bottom: '1%', display: 'flex', flexWrap: 'wrap', gap: 6, left: 0, justifyContent: 'center' }}>
                <p style={nomesStyle}>Acadêmicas: </p>
                <p style={nomesStyle}>Graziela Freisleben, </p>
                <p style={nomesStyle}>Jessica Bugança, </p>
                <p style={nomesStyle}>Julia Boareto, </p>
                <p style={nomesStyle}>Julia Oliveira, </p>
                <p style={nomesStyle}>Julia Padilha, </p>
                <p style={nomesStyle}>Laura Argenton, </p>
                <p style={nomesStyle}>Vandressa Chiamulera</p>
            </div>
        </div>
    )
}

const nomesStyle = {
    color: '#fff',
    fontWeight: 'bold',
    padding: 0,
    margin: 0,
    fontSize: 12
}
const divisorStyle = {
    margin: '0px 4px',
    color: '#fff',
}
const buttonStyle = {
    backgroundColor: '#fff',
    width: '100%',
    color: '#FF6A00',
    border: 'none',
    padding: '15px 30px',
    borderRadius: '15px',
    fontSize: '30px',
    fontWeight: 'bold',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease',
    fontFamily: 'Sensa Wild Fill'
};

buttonStyle[':hover'] = {
    backgroundColor: '#FF6A00',
    color: '#fff',
};