import { useState } from 'react';
import mainApi from '../mainApiN';

export function EnviarEmail({ title, index, updateData, data, envEmail, changeResultado }) {
    const [email, setEmail] = useState('');
    const soma = data.reduce((acumulador, item) => {
        return acumulador + item.resposta;
    }, 0);
    const whatResposta = soma >= 25 ? 3 : soma >= 18 ? 2 : soma >= 12 ? 1 : 0
    const sendEmail = async () => {
        const html = emailHtml.replace('{{title}}', respostas[whatResposta].title).replace('{{subtitle}}', respostas[whatResposta].subtitle)
        alert('E-mail enviado. Aguarde alguns segundos e verifique sua caixa de entrada.')
        const send = await mainApi('sendEmailEscala', 'POST', { email: email, html: html })
    }
    const image1 = require(`../assets/images/mais1.png`)
    const image2 = require(`../assets/images/mais2.png`)
    const image3 = require(`../assets/images/mais3.png`)

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = () => {
        if (email) {
            sendEmail();
        } else {
            alert('Por favor, insira um endereço de e-mail válido.');
        }
    };
    const setButtonStyle = (idx) => {
        return idx === 2 ? { ...buttonStyle, ...voltarButton } : buttonStyle
    }

    return (
        <div style={{ height: '100%', padding: 5, width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column', position: 'relative', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <h1 style={{ color: '#fff', fontWeight: 'bold', fontFamily: 'Amazing Slab Trial', fontSize: 30, lineHeight: 1 }}>Qual seu e-mail?</h1>
                <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Digite seu e-mail"
                    style={inputStyle}
                />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <button className="buttonPergunta" style={setButtonStyle(1)} onClick={handleSubmit}>Enviar</button>
                    <button className="buttonPergunta" style={setButtonStyle(2)} onClick={() => changeResultado()}>Voltar</button>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}>
                <img className="imageMais" src={image1} style={{ width: '50%', maxHeight: 180, objectFit: 'contain', position: 'relative', left: '8%' }} />
                <img className="imageMais" src={image2} style={{ width: '50%', maxHeight: 180, objectFit: 'contain' }} />
                <img className="imageMais" src={image3} style={{ width: '50%', maxHeight: 180, objectFit: 'contain', position: 'relative', right: '8%' }} />
            </div>
        </div>
    );
}

const emailHtml = `<!DOCTYPE html>
<html lang="pt-BR">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Resultado da Avaliação de Ansiedade</title>
    <style>
        body {
            font-family: 'Arial', sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
        }
        .email-container {
            background-color: #fff;
            width: 100%;
            max-width: 600px;
            margin: 20px auto;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        .header {
            background-color: #FF6A00;
            padding: 10px;
            text-align: center;
            border-radius: 8px 8px 0 0;
        }
        .header h1 {
            color: #fff;
            font-size: 24px;
            margin: 0;
        }
        .content {
            padding: 20px;
            text-align: center;
        }
        .content h2 {
            color: #333;
            font-size: 22px;
        }
        .content p {
            color: #666;
            font-size: 16px;
            line-height: 1.5;
        }
        .result {
            background-color: #eee;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;
            font-size: 18px;
            font-weight: bold;
            color: #FF6A00;
        }
        .disclaimer {
            font-size: 12px;
            color: #999;
            margin-top: 20px;
            text-align: center;
        }
        .footer {
            text-align: center;
            padding: 20px;
            background-color: #f4f4f4;
            border-radius: 0 0 8px 8px;
            font-size: 14px;
            color: #333;
        }
    </style>
</head>
<body>
    <div class="email-container">
        <div class="header">
            <h1>Resultado da Avaliação de Ansiedade</h1>
        </div>
        <div class="content">
            <h2>Seu Resultado</h2>
            <div class="result">
                <!-- Título da Resposta -->
                {{title}}
            </div>
            <p>
                <!-- Subtítulo da Resposta -->
                {{subtitle}}
            </p>
        </div>
        <div class="content">
            <p>
                A Escala de Hamilton não deve ser utilizada como um instrumento de diagnóstico definitivo. Para obter um resultado preciso, é essencial uma avaliação conduzida por um profissional qualificado.
            </p>
            <p>
                Caso você experimente sintomas que afetem negativamente sua qualidade de vida, é recomendado buscar a orientação de um psicólogo ou profissional de saúde mental.
            </p>
        </div>
        <div class="footer">
            <p>Obrigado por usar nossa avaliação. Cuide da sua saúde mental!</p>
        </div>
    </div>
</body>
</html>
`

const inputStyle = {
    padding: '15px',
    fontSize: '20px',
    width: '100%',
    margin: '10px 0',
    borderRadius: '10px',
    border: 'none',
    boxSizing: 'border-box',
    fontFamily: 'Sensa Wild Fill',
    color: '#FF6A00',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease',
};

const buttonStyle = {
    backgroundColor: '#fff',
    marginTop: 20,
    width: '100%',
    color: '#FF6A00',
    border: 'none',
    padding: '15px 30px',
    borderRadius: '15px',
    fontSize: '25px',
    fontWeight: 'bold',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease',
    fontFamily: 'Sensa Wild Fill'
};
const voltarButton = {
    color: 'rgba(0,0,0,0.5)',
}
buttonStyle[':hover'] = {
    backgroundColor: '#FF6A00',
    color: '#fff',
};

const respostas = [
    {
        title: 'Ansiedade Normal',
        subtitle: 'Apresenta sintomas de ansiedade que estão dentro dos limites esperados para situações cotidianas ou que são leves e não causam prejuízo significativo ao funcionamento diário.'
    },
    {
        title: 'Ansiedade Leve',
        subtitle: 'Embora os sintomas estejam presentes, eles são frequentemente temporários e podem ser aliviados com técnicas de gerenciamento de estresse ou intervenções psicoterapêuticas.'
    },
    {
        title: 'Ansiedade Moderada',
        subtitle: 'Apresenta sintomas mais persistentes que começam afetar de forma mais significativa a vida cotidiana. Pode ser importante intervenção, como psicoterapia, para ajudar a gerenciar os sintomas e prevenir a progressão para ansiedade grave.'
    },
    {
        title: 'Ansiedade Grave',
        subtitle: 'Os sintomas são persistentes e podem interferir de forma substancial no funcionamento diário, nas relações pessoais e profissionais, e na qualidade de vida em geral. A ansiedade grave muitas vezes requer tratamento especializado, para ajudar a gerenciar os sintomas. É crucial que indivíduos que enfrentam ansiedade grave busquem ajuda de um profissional de saúde mental.'
    },
]