export function Perguntas({ title, index, updateData, data }) {
    const image = require(`../assets/images/pergunta${index + 1}.png`)
    let thisData = data.filter((e) => e.id === index)
    thisData = thisData[0]
    const setAnswer = (idx) => {
        const type = idx === 5 ? 'voltar' : 'add'
        updateData({
            id: index,
            resposta: idx
        }, type)
    }
    const setButtonStyle = (idx) => {
        return idx === 5 ? { ...buttonStyle, ...voltarButton } : idx === thisData?.resposta ? { ...buttonStyle, ...selectedButton } : buttonStyle
    }
    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', padding: 30, paddingTop: 0 }}>
                <div style={{ background: '#fff', height: '50%', display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center', borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                    <img className="image-pergunta" src={image} style={{ width: '80%', maxWidth: 300, objectFit: 'contain', borderRadius: 10 }} />
                    <div style={{ width: '80%' }}>
                        <p>{title}</p>
                    </div>
                </div>
                <div style={{ height: '48%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {buttons.map((e, idx) => {
                        return (
                            <button className="buttonPergunta" onClick={() => setAnswer(idx)} style={setButtonStyle(idx)}>{e}</button>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const buttons = ['Ausente', 'Ligeira', 'Moderada', 'Frequente', 'Muito Frequente', 'Voltar']

const buttonStyle = {
    backgroundColor: '#f4aa56',
    width: '100%',
    color: '#fff',
    border: 'none',
    padding: '15px 30px',
    borderRadius: '15px',
    fontSize: '25px',
    fontWeight: 'bold',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.5)',
    transition: 'all 0.3s ease',
    fontFamily: 'Sensa Wild Fill'
};

const selectedButton = {
    backgroundColor: '#fff',
    color: '#FF6A00',
}
const voltarButton = {
    backgroundColor: '#D9D9D9',
    color: '#000',
}

buttonStyle[':hover'] = {
    backgroundColor: '#FF6A00',
    color: '#fff',
};