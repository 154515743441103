export function Resultado({ title, index, maisResultados, data, activeResposta, envEmail }) {
    const soma = data.reduce((acumulador, item) => {
        return acumulador + item.resposta;
    }, 0);
    console.log(activeResposta, '<<<<<<<<<<<<<<<<<<')
    const whatResposta = ![null, undefined, ''].includes(activeResposta) ? activeResposta : soma >= 25 ? 3 : soma >= 18 ? 2 : soma >= 12 ? 1 : 0
    const resposta = respostas[whatResposta]
    const image = require(`../assets/images/resposta${whatResposta + 1}.png`)

    return (
        <div className="resultado" style={{ height: '100%', padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', position: 'relative', height: '30%', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ width: '100%', zIndex: 2 }}>
                    <h2 style={{ color: '#fff', fontWeight: 'bold', fontFamily: 'Amazing Slab Trial', fontSize: 30 }}>{![null, undefined, ''].includes(activeResposta) ? '' : 'Seu resultado foi: '}</h2>
                    <h1 style={{ color: '#fff', fontWeight: 'regular', fontSize: whatResposta === 0 ? 45 : [2].includes(whatResposta) ? 40 : 50 }}>{resposta.title}</h1>
                </div>
                <div style={{ width: whatResposta === 0 ? '1%' : '5%', height: '100%', position: 'relative', zIndex: 1 }}>
                    <img src={image} style={{ width: whatResposta === 2 ? 250 : 300, objectFit: 'contain', position: 'absolute', right: whatResposta % 2 ? -100 : -50, bottom: 0 }} />
                </div>
            </div>
            <div style={{ background: '#fff', fontFamily: 'Amazing Slab Trial', borderRadius: 20, padding: whatResposta === 3 ? 10 : 20, width: '80%' }}>
                <p className="resultadoTexto" style={{ color: '#000', fontFamily: 'sans-serif', textAlign: 'center', lineHeight: 1.2, fontSize: [3, 2].includes(whatResposta) ? 15 : 18 }}>{resposta.subtitle}</p>
            </div>
            <div style={{ borderRadius: 20, padding: whatResposta === 3 ? 10 : 20, width: '90%' }}>
                <p style={{ color: '#fff', textAlign: 'center', fontFamily: 'sans-serif', fontSize: 12 }}>A Escala de Hamilton não deve ser utilizada como um instrumento de diagnóstico definitivo. Para obter um resultado preciso, é essencial uma avaliação conduzida por um profissional qualificado. Caso você experimente sintomas que afetem negativamente sua qualidade de vida, é recomendado buscar a orientação de um psicólogo.</p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                <button className="buttonPergunta" style={buttonStyle} onClick={maisResultados}>VER OUTROS RESULTADOS</button>
                <button className="buttonPergunta" style={buttonStyle} onClick={envEmail}>ENVIAR RESULTADO POR E-MAIL</button>
            </div>
        </div>
    )
}

const respostas = [
    {
        title: 'Ansiedade Normal',
        subtitle: 'Apresenta sintomas de ansiedade que estão dentro dos limites esperados para situações cotidianas ou que são leves e não causam prejuízo significativo ao funcionamento diário.'
    },
    {
        title: 'Ansiedade Leve',
        subtitle: 'Embora os sintomas estejam presentes, eles são frequentemente temporários e podem ser aliviados com técnicas de gerenciamento de estresse ou intervenções psicoterapêuticas.'
    },
    {
        title: 'Ansiedade Moderada',
        subtitle: 'Apresenta sintomas mais persistentes que começam afetar de forma mais significativa a vida cotidiana. Pode ser importante intervenção, como psicoterapia, para ajudar a gerenciar os sintomas e prevenir a progressão para ansiedade grave.'
    },
    {
        title: 'Ansiedade Grave',
        subtitle: 'Os sintomas são persistentes e podem interferir de forma substancial no funcionamento diário, nas relações pessoais e profissionais, e na qualidade de vida em geral. A ansiedade grave muitas vezes requer tratamento especializado, para ajudar a gerenciar os sintomas. É crucial que indivíduos que enfrentam ansiedade grave busquem ajuda de um profissional de saúde mental.'
    },
]

const buttonStyle = {
    backgroundColor: '#fff',
    width: '100%',
    color: '#FF6A00',
    border: 'none',
    padding: '15px 30px',
    borderRadius: '15px',
    fontSize: '25px',
    fontWeight: 'bold',
    cursor: 'pointer',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'all 0.3s ease',
    fontFamily: 'Sensa Wild Fill'
};

buttonStyle[':hover'] = {
    backgroundColor: '#FF6A00',
    color: '#fff',
};